/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


require('./bootstrap.min.js');
require('./modernizr.js');
require('./scrollIt.min.js');
require('./nav-menu.js');
require('./easy.responsive.tabs.js');
require('owl.carousel/dist/owl.carousel.js');
require('./jquery.counterup.min');
require('waypoints/lib/jquery.waypoints.js');
require('./tabs.min.js');
require('./countdown.js');
require('./isotope.pkgd.min');
import { WOW } from 'wowjs';
window.WOW = WOW;
require('./jquery.fancybox.min');
import Blazy from 'blazy';
require('./main.js');

window.onload = function() {
    // Initialize
    var bLazy = new Blazy({
        success: function(element){
            setTimeout(function(){
                // We want to remove the loader gif now.
                // First we find the parent container
                // then we remove the "loading" class which holds the loader image
                var parent = element.parentNode;
                parent.className = parent.className.replace(/\bloading\b/,'');
            }, 200);
        },
        offset:20,
    });
};



/*window.Vue = require('vue');*/

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

/*Vue.component('example-component', require('./components/ExampleComponent.vue').default);*/

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/*const app = new Vue({
    el: '#app',
});*/
$(document).on("ready", function() {
    var bLazy = new Blazy({
        success: function (element) {
            setTimeout(function () {
                // We want to remove the loader gif now.
                // First we find the parent container
                // then we remove the "loading" class which holds the loader image
                var parent = element.parentNode;
                parent.className = parent.className.replace(/\bloading\b/, '');
            }, 200);
        },
        offset: 20,
    });
});








/*

*/
